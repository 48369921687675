import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { SanityProduct } from 'src/graphql-types';

import { ContentBox, Split, Title } from '../components/ContentBox';
import { PriceRow, Prices } from '../components/PriceRow';
import Layout from '../layout';
import { serializers } from '../serializers';

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      name
      _rawInfo
      options {
        name
        price
        feeds
        minOrder
      }
      images {
        asset {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  }
`;

interface ProductTemplateProps {
  data: {
    product: SanityProduct;
  };
}

const ProductTemplate = (props: ProductTemplateProps) => {
  const {
    data: { product },
  } = props;
  return (
    <Layout title={product.name}>
      <ContentBox>
        <Split>
          <div className="grid grid-cols-2 gap-10">
            {product.images.map((image, index) => {
              return (
                <GatsbyImage
                  alt={product.name + index}
                  className={index === 0 ? 'col-span-2' : ''}
                  image={image.asset.gatsbyImageData}
                  key={product.name + index}
                />
              );
            })}
          </div>
        </Split>

        <Split>
          <Title>{product.name}</Title>
          <Prices>
            {product.options.map(({ name, feeds, price, minOrder }) => {
              return (
                <PriceRow
                  key={name}
                  title={name}
                  feeds={feeds}
                  minOrder={minOrder}
                  price={`$${price}`}
                />
              );
            })}
          </Prices>
          <BlockContent blocks={product._rawInfo} serializers={serializers} />
        </Split>
      </ContentBox>
    </Layout>
  );
};

export default ProductTemplate;
