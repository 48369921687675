import React, { ReactNode } from 'react';

export const Prices = ({ children }: { children: ReactNode }) => {
  return <div className="px-8 py-6 mb-8 space-y-4 bg-gray-50">{children}</div>;
};

interface PriceRowProps {
  title: string;
  feeds: string;
  price: string;
  minOrder: number;
}

export const PriceRow = ({ title, feeds, price, minOrder }: PriceRowProps) => {
  const person = feeds === '1' ? 'Person' : 'People';

  return (
    <p className="flex items-center pb-4 mb-0 border-b border-gray-200 last:border-0 last:pb-0">
      <span className="flex flex-col">
        <span className="text-xl">{title}</span>
        <span className="flex items-center space-x-2 text-sm opacity-50">
          <Svg />
          <span>
            {feeds} {person}
          </span>
        </span>
      </span>
      <span className="ml-auto text-xl text-right">
        {price}
        <span className="block m-0 text-xs">
          <span className="opacity-50">
            {minOrder && `Minimum order of ${minOrder} — `}
          </span>
          <span className="opacity-25">plus tax.</span>
        </span>
      </span>
    </p>
  );
};

const Svg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-users"
    >
      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
      <circle cx="9" cy="7" r="4" />
      <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    </svg>
  );
};
